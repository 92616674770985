// 有害信息提交
import request from '../request'

export default class Youhai {
    /**
* 添加有害信息
* @param {*} params
* @returns
*/
    static add(data) {
        return request.post('/api/reportinfo/add', data)
    }
    /**
* 有害信息专用上传接口
* @param {*} params
* @returns
*/
    static upload(data) {
        return request.post('api/common/upload_public', data)
    }

        /**
   * 获取发布提问里的区域
   * @param {*} params
   * @returns
   */
        static unitList() {
            return request.get('/api/v1/department/unitList')
        }
}